import React, { FunctionComponent } from "react";
import { graphql } from "gatsby";

import { Timeline, Typography } from "antd";
import SEO from "../components/Seo";
import { DefaultLayout } from "../components/DefaultLayout";
import { ContentCard } from "../components/ContentCard";
import Img, { FluidObject } from "gatsby-image";
import { colors } from "../theme/variables";
import styled from "@emotion/styled";

const StyledTimelineItem = styled(Timeline.Item)`
  .ant-timeline-item-label {
    width: calc(25% - 1rem) !important;
  }

  .ant-timeline-item-content {
    left: calc(25% - 4px) !important;
    width: calc(75% - 14px) !important;
  }

  .ant-timeline-item-tail {
    left: 25% !important;
  }

  .ant-timeline-item-head {
    border-color: ${colors.blue.primary} !important;
    left: 25% !important;
  }
`;

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${colors.blue.primary};
  }
`;

type ProfilePageProps = {
  data: {
    profileImage: {
      childImageSharp: { fluid: FluidObject };
    };
  };
};

const Profile: FunctionComponent<ProfilePageProps> = ({ data }) => {
  return (
    <>
      <SEO title="Profil" />
      <DefaultLayout>
        <ContentCard
          title={
            <StyledTitle level={4}>
              Horst Ehlers Architekt (baugewerblich)
            </StyledTitle>
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Img
              fluid={data.profileImage.childImageSharp.fluid}
              style={{ width: "10rem" }}
            />
            <p style={{ textAlign: "center", marginTop: "2rem" }}>
              geboren am 29.10.1968 in Haldensleben (Sachsen Anhalt),
              verheiratet, 3 Kinder (deutsch, englisch, französisch)
            </p>
          </div>
        </ContentCard>
        <ContentCard title={<StyledTitle level={4}>Ausbildung</StyledTitle>}>
          <Timeline mode="left">
            <StyledTimelineItem label="1989-1995">
              Architekturstudium an der HAB Weimar (jetzt Bauhaus- Universität
              Weimar)
            </StyledTimelineItem>
            <StyledTimelineItem label="1992 – 1993">
              Studium an der University of Sheffield (England)
            </StyledTimelineItem>
            <StyledTimelineItem label="1995">
              Diplom: Entwurf einer Sekundarschule in Hermsdorf (Prof. Dr. Kari
              Johani Jormakka; Prof. Dr. Gerd Zimmermann)
            </StyledTimelineItem>
          </Timeline>
        </ContentCard>
        <ContentCard title={<StyledTitle level={4}>Baugewerblich</StyledTitle>}>
          <Timeline mode="left">
            <StyledTimelineItem label="1993 – 1995">
              Freier Mitarbeiter bei architektengemeinschaft nitschke + donath,
              weimar
            </StyledTimelineItem>
            <StyledTimelineItem label="1995 – 1996">
              Angestellt bei multiplan weimar GmbH
            </StyledTimelineItem>
            <StyledTimelineItem label="1996 – 2004">
              Angestellt bei architektengemeinschaft nitschke + donath, weimar
              (ab 2001 als Außenstelle in Frankfurt am Main)
            </StyledTimelineItem>
          </Timeline>
        </ContentCard>
        <ContentCard title={<StyledTitle level={4}>Sonstiges</StyledTitle>}>
          <Timeline mode="left">
            <StyledTimelineItem label="1999">
              Ausbildung zum SIGEKO nach Baustellenverordnung
            </StyledTimelineItem>
            <StyledTimelineItem label="2001">
              Eintragung in die Architektenkammer Hessen (Nr. 16185)
            </StyledTimelineItem>
            <StyledTimelineItem label="2004">
              Gründung Architekturbüro Horst Ehlers
            </StyledTimelineItem>
            <StyledTimelineItem label="2009">
              Geschäftsführer der
              <span style={{ fontStyle: "italic", margin: "0 0.25rem" }}>
                [<span style={{ color: "red" }}>XXL</span>]
              </span>
              bauteam GmbH
            </StyledTimelineItem>
          </Timeline>
        </ContentCard>
      </DefaultLayout>
    </>
  );
};

export default Profile;

export const data = graphql`
  {
    profileImage: file(relativePath: { eq: "Profil.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
